<template>
  <container style="max-width: 950px">
    <input
      type="file"
      ref="file_interface"
      @change="handleImportInterface"
      v-show="false"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      multiple
    />
    <input
      type="file"
      ref="file_data"
      @change="handleImportData"
      v-show="false"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      multiple
    />
    <v-card :loading="loading" outlined>
      <v-card-title>
        {{ $capitalize($tc("model.i18n_page_title")) }}
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab
            :disabled="
              !$store.getters['auth/getScope']('interface_translate.read')
            "
            >{{ $capitalize($tc("model.i18n_interface_tab")) }}</v-tab
          >
          <v-tab
            :disabled="!$store.getters['auth/getScope']('data_translate.read')"
            >{{ $capitalize($tc("model.i18n_data_tab")) }}</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="my-5">
              <v-row>
                <ScopeProvider scope="interface_import_translations">
                  <v-btn
                    class="mx-3"
                    color="primary"
                    outlined
                    @click="openImportInterfaceDialog"
                    :loading="importing_interface"
                  >
                    <v-icon size="24" left>mdi-upload</v-icon>
                    {{ $capitalize($tc("model.file_import_button")) }}
                  </v-btn>
                </ScopeProvider>
                <v-menu :close-on-content-click="false" bottom offset-y>
                  <template v-slot:activator="{ on }">
                    <ScopeProvider scope="interface_export_translations">
                      <v-btn class="mx-3" v-on="on" outlined color="primary">
                        <v-icon size="24" left>mdi-download</v-icon>
                        {{ $capitalize($tc("model.file_export_button")) }}
                      </v-btn>
                    </ScopeProvider>
                  </template>
                  <v-card max-width="300px">
                    <v-card-text>
                      <ValidationObserver ref="export_form">
                        <ValidationProvider
                          name="i18n_languages"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            :label="$capitalize($tc('model.i18n_languages'))"
                            multiple
                            chips
                            deletable-chips
                            v-model="selected_interface_languages"
                            :items="interface_languages"
                            :loading="loading"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors"
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-action class="mx-1">
                                <v-checkbox
                                  :input-value="
                                    selected_interface_languages.includes(
                                      item.id
                                    )
                                  "
                                />
                              </v-list-item-action>
                              <v-list-item-avatar>
                                <v-avatar size="20">
                                  <v-img :src="item.country.flag_image_url">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="grey lighten-5"
                                          :size="25"
                                          width="2"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  item.translation.name
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </ValidationProvider>
                        <v-btn
                          block
                          outlined
                          :href="generateInterfaceExportLink()"
                          target="_blank"
                          :disabled="selected_interface_languages.length == 0"
                        >
                          {{ $capitalize($tc("model.file_export_button")) }}
                        </v-btn>
                      </ValidationObserver>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-row>
            </div>

            <v-card-title class="px-0">{{
              $capitalize($tc("model.i18n_progress_per_language_label"))
            }}</v-card-title>
            <v-card
              v-for="language in interface_languages"
              outlined
              :key="language.id"
              class="mb-5"
            >
              <v-card-text class="font-weight-bold">
                <v-avatar size="20" class="mr-1">
                  <v-img :src="language.flag_image_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                          :size="25"
                          width="2"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                {{
                  language.translation
                    ? language.translation.name
                    : language.name
                }}
                <v-progress-linear
                  class="mt-2"
                  :value="getProgressPercentage(language)"
                  height="22"
                  rounded
                >
                  <template v-slot:default>
                    <span>
                      {{ language.fields_translated }} /
                      {{ language.max_translations }}
                    </span>
                  </template>
                </v-progress-linear>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <div class="my-5">
              <v-row>
                <ScopeProvider scope="data_import_translations">
                  <v-btn
                    class="mx-3"
                    color="primary"
                    outlined
                    @click="openImportDataDialog"
                    :loading="importing_data"
                  >
                    <v-icon size="24" left>mdi-upload</v-icon>
                    {{ $capitalize($tc("model.file_import_button")) }}
                  </v-btn>
                </ScopeProvider>
                <v-menu :close-on-content-click="false" bottom offset-y>
                  <template v-slot:activator="{ on }">
                    <ScopeProvider scope="data_export_translations">
                      <v-btn class="mx-3" v-on="on" outlined color="primary">
                        <v-icon size="24" left>mdi-download</v-icon>
                        {{ $capitalize($tc("model.file_export_button")) }}
                      </v-btn>
                    </ScopeProvider>
                  </template>
                  <v-card max-width="300px">
                    <v-card-text>
                      <ValidationObserver ref="export_form">
                        <ValidationProvider
                          name="i18n_languages"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            :label="$capitalize($tc('model.i18n_languages'))"
                            multiple
                            chips
                            deletable-chips
                            v-model="selected_data_languages"
                            :items="interface_languages"
                            :loading="loading"
                            item-text="name"
                            item-value="id"
                            :error-messages="errors"
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-action class="mx-1">
                                <v-checkbox
                                  :input-value="
                                    selected_data_languages.includes(item.id)
                                  "
                                />
                              </v-list-item-action>
                              <v-list-item-avatar>
                                <v-avatar size="20">
                                  <v-img :src="item.country.flag_image_url">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="grey lighten-5"
                                          :size="25"
                                          width="2"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  item.translation.name
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </ValidationProvider>
                        <ValidationProvider
                          name="i18n_entities"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            v-model="selected_data_tables"
                            multiple
                            chips
                            deletable-chips
                            :label="$capitalize($tc('model.i18n_entities'))"
                            :items="data_languages"
                            item-text="neutral_table"
                            :error-messages="errors"
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-action class="mx-1">
                                <v-checkbox
                                  :input-value="
                                    selected_data_tables.includes(
                                      item.neutral_table
                                    )
                                  "
                                />
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $capitalize(item.neutral_table) }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="
                                  removeDataTableSelection(data.item)
                                "
                              >
                                {{ $capitalize(data.item.neutral_table) }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </ValidationProvider>
                        <v-btn
                          block
                          outlined
                          :href="generateDataExportLink()"
                          target="_blank"
                        >
                          {{ $capitalize($tc("model.file_export_button")) }}
                        </v-btn>
                      </ValidationObserver>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-row>
            </div>

            <v-card-title class="px-0">
              {{ $capitalize($tc("model.i18n_entities")) }}
            </v-card-title>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="item in data_languages"
                :key="item.neutral_table"
              >
                <v-expansion-panel-header class="font-weight-medium">
                  {{ $capitalize(item.neutral_table) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card-text class="px-0 font-weight-medium">{{
                    $capitalize($tc("model.i18n_progress_per_language_label"))
                  }}</v-card-text>
                  <v-card
                    v-for="language in item.progress_per_language"
                    outlined
                    :key="language.id"
                    class="mb-5"
                  >
                    <v-card-text class="font-weight-bold">
                      <v-avatar size="20" class="mr-1">
                        <v-img :src="language.flag_image_url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                                :size="25"
                                width="2"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>
                      {{ language.translation.name }}
                      <v-progress-linear
                        class="mt-2"
                        :value="getProgressPercentage(language)"
                        height="22"
                        rounded
                      >
                        <template v-slot:default>
                          <span>
                            {{ language.fields_translated }} /
                            {{ language.max_translations }}
                          </span>
                        </template>
                      </v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </container>
</template>

<script>
import {
  getInterfaceLanguagesProgress,
  getDataLanguagesProgress,
  importInterfaceTranslationFile,
  importDataTranslationFile,
} from "@/services/localizations";
import ScopeProvider from "@/components/ScopeProvider";

export default {
  components: { ScopeProvider },
  data: () => ({
    loading: false,
    importing_interface: false,
    importing_data: false,
    selected_interface_languages: [],
    interface_languages: [],
    selected_data_languages: [],
    selected_data_tables: [],
    data_languages: [],
    tab: 0,
  }),
  methods: {
    generateInterfaceExportLink() {
      const url = this.$http.getUri({
        url: process.env.VUE_APP_BASE_API + "/i18n/interface",
        params: { language_codes: this.selected_interface_languages },
      });
      return url;
    },
    generateDataExportLink() {
      const url = this.$http.getUri({
        url: process.env.VUE_APP_BASE_API + "/i18n/data",
        params: {
          language_codes: this.selected_data_languages,
          tables: this.selected_data_tables,
        },
      });
      return url;
    },
    async handleImportInterface($event) {
      this.importing_interface = true;
      try {
        const file = $event.target.files[0];
        let data = new FormData();
        data.append("translation_file", file);
        const response = await importInterfaceTranslationFile(data);

        this.$store.commit("sendMessage", {
          text: this.$tc("model.i18n_importing_success_message"),
          color: "success",
        });

        this.$router.go();
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: e.response.data.message,
          color: "red",
        });
      } finally {
        this.importing_interface = false;
      }

      this.$refs.file_interface.value = "";
    },
    async handleImportData($event) {
      this.importing_data = true;
      try {
        const file = $event.target.files[0];
        let data = new FormData();
        data.append("translation_file", file);
        const response = await importDataTranslationFile(data);

        this.$store.commit("sendMessage", {
          text: this.$tc("model.i18n_importing_success_message"),
          color: "success",
        });
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: e.response.data.message,
          color: "red",
        });
      } finally {
        this.importing_data = false;
      }

      this.$refs.file_data.value = "";
    },
    openImportInterfaceDialog() {
      this.$refs.file_interface.click();
    },
    openImportDataDialog() {
      this.$refs.file_data.click();
    },
    removeDataTableSelection(table) {
      this.selected_data_tables = this.selected_data_tables.filter(
        (neutral_table) => neutral_table != table.neutral_table
      );
    },
    async fetchInterfaceLanguagesProgress() {
      this.loading = true;
      try {
        const response = await getInterfaceLanguagesProgress();
        this.interface_languages = response;
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: this.$tc("model.i18n_error_fetch_languages"),
          color: "red",
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchDataLanguagesProgress() {
      this.loading = true;
      try {
        const response = await getDataLanguagesProgress();
        this.data_languages = response;
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: this.$tc("model.i18n_error_fetch_languages"),
          color: "red",
        });
      } finally {
        this.loading = false;
      }
    },
    getProgressPercentage(language) {
      return (language.fields_translated / language.max_translations) * 100;
    },
  },
  created() {
    if (this.$store.getters["auth/getScope"]("data_translations.read")) {
      this.fetchDataLanguagesProgress();
    }

    if (this.$store.getters["auth/getScope"]("interface_translate.read")) {
      this.fetchInterfaceLanguagesProgress();
    }
  },
};
</script>

<style></style>
